/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import arrowSvg from '@/assets/images/arrow.svg';

const InsideLinks = ({ links, classes, ...rest }) => (
  <div className={classes.linkContainer} {...rest}>
    {links.map(({ id, name, url }) => (
      <Link key={id} to={url} className={classes.link}>
        <img src={arrowSvg} alt={name} className={classes.arrow} />
        {name}
      </Link>
    ))}
  </div>
);

InsideLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      links: PropTypes.string,
    })
  ).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default InsideLinks;
