export default ({ spacing, text }) => ({
  container: {
    position: 'relative',
  },

  pageTitle: {
    ...text.dropDown,
    fontWeight: 500,
    margin: 0,
    marginBottom: spacing * 3,
  },

  title: {
    ...text.main,
    fontFamily: 'Work Sans',
    fontWeight: 600,
    marginTop: 0,
    marginBottom: spacing * 3,
  },

  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(50vw - 100px)',
    paddingRight: 10,
  },

  imageContainer: {
    position: 'relative',
    marginLeft: '4vw',
    width: '124px',
    height: '155px',
    background: '#fff',
  },

  textContainer: {
    width: 'calc(100% - 124px - 4vw)',
  },

  animate: {
    width: 1,
    height: 1,
    position: 'absolute',
    top: 0,
    left: 0,
  },

  dummyImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
  },

  '@media (min-width: 1100px) and (max-width: 1600px)': {
    container: {
      width: spacing * 60,
    },
  },

  '@media (max-width: 800px)': {
    container: {
      flex: '1 1 auto',
    },
    contentContainer: {
      position: 'relative',
      top: 0,
      left: 0,
      flexDirection: 'column-reverse',
      paddingRight: 0,
    },

    animate: {
      position: 'static',
      overflow: 'hidden',
    },

    imageContainer: {
      marginLeft: 0,
      marginBottom: spacing * 4.6,
    },

    textContainer: {
      width: 'calc(100vw - 72px)',
    },
  },
});
