import React, { useState, useMemo, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import getPerson from '@/utils/hashHandler';
import Person from './Person';
import Texts from '../Texts';
import InsideLinks from '../InsideLinks';

const Persons = ({ classes, location }) => {
  const { dataJson, allImageSharp } = useStaticQuery(graphql`
    {
      dataJson {
        pages {
          persons {
            content
          }
        }
        person {
          id
          title
          content
          image
        }
      }
      allImageSharp(filter: {resize: {originalName: {regex: "/^URO_.+\\.jpg$/"}}}) {
        nodes {
          resize {
            originalName
          }
          gatsbyImageData(
            width: 124
            height: 155
            transformOptions: {cropFocus: NORTH, fit: COVER}
          )
        }
      }
    }
  `);
  const [personId, setPersonId] = useState(null);
  const { content } = dataJson.pages.persons;
  const links = useMemo(
    () =>
      dataJson.person.map(({ id, title }) => ({
        id,
        name: title,
        url: `/stiftungsrat?person=${id}`,
      })),
    [dataJson.person]
  );
  const imageObj = useMemo(
    () =>
      allImageSharp.nodes.reduce((acc, { resize, gatsbyImageData }) => {
        const { originalName } = resize;
        acc[originalName] = { gatsbyImageData, originalName };

        return acc;
      }, {}),
    [allImageSharp.nodes]
  );

  useEffect(() => {
    const person = getPerson(location.href);
    if (person) {
      setPersonId(person);
    }
  }, [location]);

  return (
    <div className={classes.container}>
      {personId ? (
        <AnimatePresence>
          {dataJson.person.map(
            ({ id, title, image, content: text }) =>
              id === personId && <Person key={id} title={title} imageData={imageObj[image]} text={text} links={links} />
          )}
        </AnimatePresence>
      ) : (
        <>
          <p className={classes.pageTitle}>Stiftungsrat</p>
          <Texts content={content} />
          <InsideLinks links={links} />
        </>
      )}
    </div>
  );
};

Persons.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
  }).isRequired,
};

export default Persons;
